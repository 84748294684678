import * as THREE from 'three'
import React, { Suspense } from 'react'
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader'

export default function SVG( props ) {
  const svgLoader = new SVGLoader()

  const { paths } = svgLoader.parse(
    `<svg width="100%" height="100%" viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
      <g transform="matrix(200,0,0,200,-33.2812,512)">
        <rect x="0.166" y="-0.75" width="0.167" height="0.75" style="fill-rule:nonzero;"/>
      </g>
      <g transform="matrix(200,0,0,200,66.7188,512)">
        <path d="M0.084,-0L0.084,-0.75L0.121,-0.75L0.584,-0.334L0.584,-0.75L0.667,-0.75L0.667,-0L0.629,-0L0.167,-0.416L0.167,-0L0.084,-0Z" style="fill-rule:nonzero;"/>
      </g>
      <g transform="matrix(200,0,0,200,216.719,512)">
        <path d="M0.584,-0.166C0.576,-0.055 0.521,-0 0.417,-0L0.25,-0C0.204,-0 0.165,-0.016 0.133,-0.049C0.1,-0.082 0.084,-0.121 0.084,-0.166L0.084,-0.584C0.084,-0.628 0.1,-0.667 0.132,-0.7C0.164,-0.733 0.203,-0.75 0.25,-0.75L0.417,-0.75C0.522,-0.75 0.577,-0.695 0.584,-0.584L0.5,-0.584C0.493,-0.639 0.466,-0.666 0.417,-0.666L0.334,-0.666C0.311,-0.666 0.291,-0.658 0.275,-0.642C0.258,-0.626 0.25,-0.606 0.25,-0.584L0.25,-0.166C0.25,-0.144 0.258,-0.125 0.274,-0.108C0.291,-0.092 0.31,-0.084 0.334,-0.084L0.417,-0.084C0.466,-0.084 0.494,-0.111 0.5,-0.166L0.584,-0.166Z" style="fill-rule:nonzero;"/>
      </g>
      <g transform="matrix(200,0,0,200,350,512)">
        <rect x="0.084" y="-0.166" width="0.166" height="0.166" style="fill-rule:nonzero;"/>
      </g>
    </svg>`
  )

  const shapes = paths.map( path => path.toShapes( true ) ).flat()

  return (
    <group { ...props } scale={[ 0.0125, -0.0125, 0 ]}>{
      shapes.map( ( shape, index ) => (
        <mesh key={ index }>
          <extrudeGeometry args={[ shape, { depth: 1 } ]} />
          <meshStandardMaterial color="black" />
        </mesh>
      ) )
    }</group>
  )
}
