import * as THREE from 'three'
import React from 'react'
import { useLoader } from '@react-three/fiber'

const aluminumMaterial = new THREE.MeshPhysicalMaterial({
  color: 0x999999,
  emissive: 0x666666,
  metalness: 1,
  roughness: 1,
  reflectivity: 0.5,
  clearcoat: 0,
})

const chromeMaterial = new THREE.MeshPhysicalMaterial({
  color: 0xFFFFFF,
  emissive: 0x333333,
  metalness: 1,
  roughness: 0,
  reflectivity: 1,
  clearcoat: 1,
})

export default function MacProRack( props ) {
  const [ macProTexture, macProBump ] = useLoader(THREE.TextureLoader, [
    '/mac-pro-texture.png', 
    '/mac-pro-bump.png',
  ])
  macProTexture.magFilter = THREE.LinearFilter
  macProTexture.minFilter = THREE.LinearFilter
  macProTexture.mapping = THREE.CubeReflectionMapping

  const handleCurves = new THREE.CurvePath()
  handleCurves.add(
    new THREE.LineCurve3(
      new THREE.Vector3( 0, 1.25, 0 ),
      new THREE.Vector3( 0, 1.25, 0.5 )
    )
  )
  handleCurves.add(
    new THREE.QuadraticBezierCurve3(
      new THREE.Vector3( 0, 1.25, 0.5 ),
      new THREE.Vector3( 0, 1.25, 0.75 ),
      new THREE.Vector3( 0, 0.75, 0.75 )
    )
  )
  handleCurves.add(
    new THREE.LineCurve3(
      new THREE.Vector3( 0, 0.75, 0.75 ),
      new THREE.Vector3( 0, -0.75, 0.75 )
    )
  )
  handleCurves.add(
    new THREE.QuadraticBezierCurve3(
      new THREE.Vector3( 0, -0.75, 0.75 ),
      new THREE.Vector3( 0, -1.25, 0.75 ),
      new THREE.Vector3( 0, -1.25, 0.5 )
    )
  )
  handleCurves.add(
    new THREE.LineCurve3(
      new THREE.Vector3( 0, -1.25, 0.5 ),
      new THREE.Vector3( 0, -1.25, 0 )
    )
  )
  const handleGeometry = new THREE.TubeGeometry( handleCurves, 64, 0.1, 16, false )

  return (
    <group { ...props }>
      <mesh position={[ 0, 0, 0 ]} material={ aluminumMaterial }>
        <boxGeometry args={[ 6, 3.5, 8 ]} />
      </mesh>
      <mesh position={[ 0, 0, 4.1 ]} material={[
        aluminumMaterial,
        aluminumMaterial,
        aluminumMaterial,
        aluminumMaterial,
        new THREE.MeshPhongMaterial({
          map: macProTexture,
          bumpMap: macProBump,
          color: 0xCCCCCC,
          reflectivity: 0
        }),
        aluminumMaterial
      ]}>
        <boxGeometry args={[ 7, 3.5, 0.25 ]} />
      </mesh>
      <mesh
        position={[ -2.85, 0, 4.1 ]}
        geometry={ handleGeometry }
        material={ chromeMaterial }
      />
      <mesh
        position={[ 2.85, 0, 4.1 ]}
        geometry={ handleGeometry }
        material={ chromeMaterial }
      />
    </group>
  )
}
