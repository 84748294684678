import * as THREE from 'three'
import React, { useState, useEffect } from 'react'
import { Canvas } from '@react-three/fiber'

import DingDongDataLogo from './models/DingDongDataLogo'

export default function Banner() {
  const [mode, setMode] = useState( window.matchMedia('(prefers-color-scheme: dark)')?.matches ? 'dark' : 'light' );

  useEffect( () => {
    window.matchMedia( '(prefers-color-scheme: dark)' )?.addEventListener( 'change', e => setMode( e.matches ? 'dark' : 'light' ) );
  }, [] );

  return (
    <Canvas
      style={{ width: '100vw', height: '100vh' }}
      gl={{ alpha: false, antialias: false, logarithmicDepthBuffer: true }}
      camera={{ fov: 7.5, position: [ 0, 100, 100 ] }}
      onCreated={({ gl }) => {
        gl.setClearColor( mode === 'dark' ? '#808080' : 'white' )
        gl.toneMapping = THREE.ACESFilmicToneMapping
        gl.outputEncoding = THREE.sRGBEncoding
      }}
    >

      <ambientLight
        intensity={ 1 }
      />
      <directionalLight
        position={[ -50, -100, -50 ]}
        intensity={ 5 }
        color="red"
      />

      {/* <mesh position={[ 0, -50, 0 ]} rotation={[ Math.PI / 2 * -1, 0, 0.1 ]}>
        <planeGeometry args={[ 1000, 100 ]} />
        <meshBasicMaterial color={ mode === 'dark' ? '#606060' : 'white' } />
      </mesh> */}

      <DingDongDataLogo rotation={[ Math.PI / 4, 0, 0 ]} />
    </Canvas>
  )
}
