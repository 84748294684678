import * as THREE from 'three'
import React from 'react'
import { useLoader } from '@react-three/fiber'

const aluminumMaterial = new THREE.MeshPhysicalMaterial({
  color: 0x999999,
  emissive: 0x666666,
  metalness: 1,
  roughness: 1,
  reflectivity: 0.5,
  clearcoat: 0,
})

const chromeMaterial = new THREE.MeshPhysicalMaterial({
  color: 0xFFFFFF,
  emissive: 0x333333,
  metalness: 1,
  roughness: 0.5,
  reflectivity: 1,
  clearcoat: 1,
})

export default function Xserve( props ) {
  const [ xserveTexture, xserveBump ] = useLoader(THREE.TextureLoader, [
    '/xserve-texture.png',
    '/xserve-bump.png'
  ])
  xserveTexture.magFilter = THREE.LinearFilter
  xserveTexture.minFilter = THREE.LinearFilter
  xserveTexture.mapping = THREE.CubeReflectionMapping

  const screwGeometry = new THREE.CylinderGeometry( 0.1, 0.1, 0.2, 16 )

  return (
    <group { ...props }>
      <mesh position={[ 0, 0, 0 ]} material={ aluminumMaterial }>
        <boxGeometry args={[ 6, 0.75, 8 ]} />
      </mesh>
      <mesh position={[ 0, 0, 4.1 ]} material={[
        aluminumMaterial,
        aluminumMaterial,
        aluminumMaterial,
        aluminumMaterial,
        new THREE.MeshPhongMaterial({
          map: xserveTexture,
          bumpMap: xserveBump,
          color: 0xCCCCCC,
          reflectivity: 0.5
        }),
        aluminumMaterial
      ]}>
        <boxGeometry args={[ 7, 0.75, 0.25 ]} />
      </mesh>
      <mesh position={[ -3.35, 0, 4.325 ]} rotation={[ Math.PI / 2, 0, 0 ]} geometry={ screwGeometry } material={ chromeMaterial } />
      <mesh position={[ 3.35, 0, 4.325 ]} rotation={[ Math.PI / 2, 0, 0 ]} geometry={ screwGeometry } material={ chromeMaterial } />
    </group>
  )
}
