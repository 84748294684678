import * as THREE from 'three'
import React, { Suspense, useRef } from 'react'
import { useFrame, useThree } from '@react-three/fiber'

import NineteenInchRack from './NineteenInchRack'
import MacProRack from './MacProRack'
import Xserve from './Xserve'
import TitleSVG from './TitleSVG'
import IdentifierSVG from './IdentifierSVG'

function Fallback() {
  return (
    <mesh position={[ 0, 0, 0 ]} rotation={[ 0, 0, 0 ]}>
      <planeGeometry args={[ 0, 0 ]} />
      <meshLambertMaterial color="white" />
    </mesh>
  )
}

export default function DingDongDataLogo( props ) {
  const { mouse } = useThree()
  const ref = useRef()

  useFrame( () => {
    ref.current.rotation.x = window.y / 8
    ref.current.rotation.y = Math.PI / 4 + window.x / 2
    ref.current.rotation.z = window.y / 8
  } )

  return (
    <group { ...props } ref={ ref } position={[ 0, 0, 0 ]}>
      <NineteenInchRack />
      <Suspense fallback={ <Fallback /> }>
        <MacProRack position={[ 0, 1.75, 0 ]} />
      </Suspense>
      <Suspense fallback={ <Fallback /> }>
        <Xserve position={[ 0, -0.5, 0 ]} />
      </Suspense>
      <TitleSVG
        position={[ -4, 2.5, -3.8 ]}
        rotation={[ 0, ( Math.PI / 2 ) * -1, 0 ]}
      />
      <IdentifierSVG
        position={[ -2.5, 2.5, 4 ]}
        rotation={[ 0, ( ( Math.PI / 2 ) * -1 ) + Math.PI / 2, 0 ]}
      />
    </group>
  )
}
