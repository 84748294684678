import React from 'react';
import ReactDOM from 'react-dom';
import Banner from './Banner';
import reportWebVitals from './reportWebVitals';
// import './index.css';
import './styles.css';

window.x = window.y = window.z = 0 

window.addEventListener( 'mousemove', ({ pageX, pageY }) => {
  const { innerWidth, innerHeight } = window
  window.x = ( innerWidth / 2 - pageX ) / innerWidth
  window.y = ( innerHeight / 2 - pageY ) / innerHeight
  window.z = 0
}, true )

window.addEventListener( 'scroll', () => {
  window.y = window.scrollY / 200
}, true )

ReactDOM.render(
  <React.StrictMode>
    <Banner />

    {/* <header>
      <img src="/logo.png" />
      <div>Ding Dong Data Inc.</div>
      <div>https://di.ngdo.ng/data</div>
      <div><a href="mailto://di@ngdo.ng">di@ngdo.ng</a></div>
    </header>

    <section>
      <p>Ding Dong Data Inc. is Toronto headquarted software development company. It was founded by Andrew Odri in 2020, the international year of the dumpster fire.</p>
      <p>We specialize in solving weird problems creatively and efficiently. Do you require a solution weirder than these?</p>
      <ul>
        <li>Wrapping Windows 95 COM+ DLLs and exposing core functionality via GraphQL</li>
        <li>Utilizing SDR to capture, demodulate and decode marine radio packet communcations, and then dump them into AWS DynamoDB</li>
        <li>Designing and manufacturing LiPo powered devices the size of a matchbox to perform transparent MITM packet interception and manipulation</li>
        <li>Creating data driven WebGL 3D visualizations for desktop and mobile browsers</li>
      </ul>
      <p>If so, we would love to know!</p>
      <p>We like cloud infrastructure, front-end development, IoT design and fabrication, performance engineering and all manner of radiocommunications... But we <strong>love</strong> having the opportunity to combine some of these things.</p>
    </section>

    <footer>
      <span>Copyright 2020 Ding Dong Data Inc.</span>
    </footer> */}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
