import * as THREE from 'three'
import React, { Suspense } from 'react'
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader'

export default function SVG( props ) {
  const svgLoader = new SVGLoader()

  const { paths } = svgLoader.parse(
    `<svg width="100%" height="100%" viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
      <g transform="matrix(200,0,0,200,-16.7188,150.013)">
        <path d="M0.084,-0L0.084,-0.75L0.417,-0.75C0.463,-0.75 0.502,-0.734 0.535,-0.701C0.567,-0.668 0.584,-0.629 0.584,-0.584L0.584,-0.166C0.584,-0.121 0.567,-0.082 0.535,-0.049C0.502,-0.016 0.463,-0 0.417,-0L0.084,-0ZM0.25,-0.084L0.334,-0.084C0.357,-0.084 0.376,-0.092 0.393,-0.108C0.409,-0.125 0.417,-0.144 0.417,-0.166L0.417,-0.584C0.417,-0.606 0.409,-0.626 0.392,-0.642C0.376,-0.658 0.356,-0.666 0.334,-0.666L0.25,-0.666L0.25,-0.084Z" style="fill-rule:nonzero;"/>
      </g>
      <g transform="matrix(200,0,0,200,137.372,150.013)">
        <rect x="0.166" y="-0.75" width="0.167" height="0.75" style="fill-rule:nonzero;"/>
      </g>
      <g transform="matrix(200,0,0,200,249.589,150.013)">
        <path d="M0.084,-0L0.084,-0.75L0.121,-0.75L0.584,-0.334L0.584,-0.75L0.667,-0.75L0.667,-0L0.629,-0L0.167,-0.416L0.167,-0L0.084,-0Z" style="fill-rule:nonzero;"/>
      </g>
      <g transform="matrix(200,0,0,200,395.281,150.013)">
        <path d="M0.334,-0.416L0.584,-0.416L0.584,-0.166C0.584,-0.111 0.566,-0.07 0.53,-0.042C0.495,-0.014 0.457,-0 0.417,-0L0.25,-0C0.204,-0 0.165,-0.016 0.133,-0.049C0.1,-0.082 0.084,-0.121 0.084,-0.166L0.084,-0.584C0.084,-0.628 0.1,-0.667 0.132,-0.7C0.164,-0.733 0.203,-0.75 0.25,-0.75L0.417,-0.75C0.522,-0.75 0.577,-0.695 0.584,-0.584L0.5,-0.584C0.493,-0.639 0.466,-0.666 0.417,-0.666L0.334,-0.666C0.311,-0.666 0.291,-0.658 0.275,-0.642C0.258,-0.626 0.25,-0.606 0.25,-0.584L0.25,-0.166C0.25,-0.143 0.258,-0.124 0.275,-0.108C0.291,-0.092 0.311,-0.084 0.334,-0.084C0.387,-0.086 0.415,-0.114 0.417,-0.166L0.417,-0.334L0.334,-0.334L0.334,-0.416Z" style="fill-rule:nonzero;"/>
      </g>
      <g transform="matrix(200,0,0,200,-16.7188,331.013)">
        <path d="M0.084,-0L0.084,-0.75L0.417,-0.75C0.463,-0.75 0.502,-0.734 0.535,-0.701C0.567,-0.668 0.584,-0.629 0.584,-0.584L0.584,-0.166C0.584,-0.121 0.567,-0.082 0.535,-0.049C0.502,-0.016 0.463,-0 0.417,-0L0.084,-0ZM0.25,-0.084L0.334,-0.084C0.357,-0.084 0.376,-0.092 0.393,-0.108C0.409,-0.125 0.417,-0.144 0.417,-0.166L0.417,-0.584C0.417,-0.606 0.409,-0.626 0.392,-0.642C0.376,-0.658 0.356,-0.666 0.334,-0.666L0.25,-0.666L0.25,-0.084Z" style="fill-rule:nonzero;"/>
      </g>
      <g transform="matrix(200,0,0,200,120.615,331.013)">
        <path d="M0.25,-0.75L0.417,-0.75C0.463,-0.75 0.502,-0.734 0.535,-0.701C0.567,-0.668 0.584,-0.629 0.584,-0.584L0.584,-0.166C0.584,-0.121 0.567,-0.082 0.535,-0.049C0.502,-0.016 0.463,-0 0.417,-0L0.25,-0C0.204,-0 0.165,-0.016 0.133,-0.049C0.1,-0.082 0.084,-0.121 0.084,-0.166L0.084,-0.584C0.084,-0.629 0.1,-0.668 0.133,-0.701C0.165,-0.734 0.204,-0.75 0.25,-0.75ZM0.25,-0.584L0.25,-0.166C0.25,-0.144 0.258,-0.125 0.274,-0.108C0.291,-0.092 0.31,-0.084 0.334,-0.084C0.357,-0.084 0.376,-0.092 0.393,-0.108C0.409,-0.125 0.417,-0.144 0.417,-0.166L0.417,-0.584C0.417,-0.606 0.409,-0.626 0.392,-0.642C0.376,-0.658 0.356,-0.666 0.334,-0.666C0.311,-0.666 0.291,-0.658 0.275,-0.642C0.258,-0.626 0.25,-0.606 0.25,-0.584Z" style="fill-rule:nonzero;"/>
      </g>
      <g transform="matrix(200,0,0,200,249.589,331.013)">
        <path d="M0.084,-0L0.084,-0.75L0.121,-0.75L0.584,-0.334L0.584,-0.75L0.667,-0.75L0.667,-0L0.629,-0L0.167,-0.416L0.167,-0L0.084,-0Z" style="fill-rule:nonzero;"/>
      </g>
      <g transform="matrix(200,0,0,200,395.281,331.013)">
        <path d="M0.334,-0.416L0.584,-0.416L0.584,-0.166C0.584,-0.111 0.566,-0.07 0.53,-0.042C0.495,-0.014 0.457,-0 0.417,-0L0.25,-0C0.204,-0 0.165,-0.016 0.133,-0.049C0.1,-0.082 0.084,-0.121 0.084,-0.166L0.084,-0.584C0.084,-0.628 0.1,-0.667 0.132,-0.7C0.164,-0.733 0.203,-0.75 0.25,-0.75L0.417,-0.75C0.522,-0.75 0.577,-0.695 0.584,-0.584L0.5,-0.584C0.493,-0.639 0.466,-0.666 0.417,-0.666L0.334,-0.666C0.311,-0.666 0.291,-0.658 0.275,-0.642C0.258,-0.626 0.25,-0.606 0.25,-0.584L0.25,-0.166C0.25,-0.143 0.258,-0.124 0.275,-0.108C0.291,-0.092 0.311,-0.084 0.334,-0.084C0.387,-0.086 0.415,-0.114 0.417,-0.166L0.417,-0.334L0.334,-0.334L0.334,-0.416Z" style="fill-rule:nonzero;"/>
      </g>
      <g transform="matrix(200,0,0,200,-16.7188,512)">
        <path d="M0.084,-0L0.084,-0.75L0.417,-0.75C0.463,-0.75 0.502,-0.734 0.535,-0.701C0.567,-0.668 0.584,-0.629 0.584,-0.584L0.584,-0.166C0.584,-0.121 0.567,-0.082 0.535,-0.049C0.502,-0.016 0.463,-0 0.417,-0L0.084,-0ZM0.25,-0.084L0.334,-0.084C0.357,-0.084 0.376,-0.092 0.393,-0.108C0.409,-0.125 0.417,-0.144 0.417,-0.166L0.417,-0.584C0.417,-0.606 0.409,-0.626 0.392,-0.642C0.376,-0.658 0.356,-0.666 0.334,-0.666L0.25,-0.666L0.25,-0.084Z" style="fill-rule:nonzero;"/>
      </g>
      <g transform="matrix(200,0,0,200,120.615,512)">
        <path d="M0.084,-0L0.084,-0.584C0.084,-0.629 0.1,-0.668 0.133,-0.701C0.165,-0.734 0.204,-0.75 0.25,-0.75L0.417,-0.75C0.463,-0.75 0.502,-0.734 0.535,-0.701C0.567,-0.668 0.584,-0.629 0.584,-0.584L0.584,-0L0.417,-0L0.417,-0.334L0.25,-0.334L0.25,-0L0.084,-0ZM0.25,-0.416L0.417,-0.416L0.417,-0.584C0.417,-0.606 0.409,-0.626 0.392,-0.642C0.376,-0.658 0.356,-0.666 0.334,-0.666C0.311,-0.666 0.291,-0.658 0.275,-0.642C0.258,-0.626 0.25,-0.606 0.25,-0.584L0.25,-0.416Z" style="fill-rule:nonzero;"/>
      </g>
      <g transform="matrix(200,0,0,200,274.667,512)">
        <path d="M0.166,-0L0.166,-0.666L0,-0.666L0,-0.75L0.5,-0.75L0.5,-0.666L0.334,-0.666L0.334,-0L0.166,-0Z" style="fill-rule:nonzero;"/>
      </g>
      <g transform="matrix(200,0,0,200,395.281,512)">
        <path d="M0.084,-0L0.084,-0.584C0.084,-0.629 0.1,-0.668 0.133,-0.701C0.165,-0.734 0.204,-0.75 0.25,-0.75L0.417,-0.75C0.463,-0.75 0.502,-0.734 0.535,-0.701C0.567,-0.668 0.584,-0.629 0.584,-0.584L0.584,-0L0.417,-0L0.417,-0.334L0.25,-0.334L0.25,-0L0.084,-0ZM0.25,-0.416L0.417,-0.416L0.417,-0.584C0.417,-0.606 0.409,-0.626 0.392,-0.642C0.376,-0.658 0.356,-0.666 0.334,-0.666C0.311,-0.666 0.291,-0.658 0.275,-0.642C0.258,-0.626 0.25,-0.606 0.25,-0.584L0.25,-0.416Z" style="fill-rule:nonzero;"/>
      </g>
    </svg>`
  )

  const shapes = paths.map( path => path.toShapes( true ) ).flat()

  return (
    <group { ...props } scale={[ 0.0125, -0.0125, 0 ]}>{
      shapes.map( ( shape, index ) => (
        <mesh key={ index }>
          <extrudeGeometry args={[ shape, { depth: 1 } ]} />
          <meshStandardMaterial color="black" />
        </mesh>
      ) )
    }</group>
  )
}
