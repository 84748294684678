import * as THREE from 'three'
import React from 'react'

export default function NineteenInchRack( props ) {
  const anodizedBlackMaterial = new THREE.MeshPhysicalMaterial({
    color: 0x000000,
    emissive: 0x000000,
    metalness: 1,
    roughness: 0.75,
    reflectivity: 0.25,
    clearcoat: 1,
  })

  return (
    <group { ...props }>
      <mesh material={ anodizedBlackMaterial } position={[ -3.5, 3.5, 0 ]}>
        <boxGeometry args={[ 1, 1, 8 ]} />
      </mesh>
      <mesh material={ anodizedBlackMaterial } position={[ -3.5, 0, 3.5 ]} >
        <boxGeometry args={[ 1, 8, 1 ]} />
      </mesh>
      <mesh material={ anodizedBlackMaterial } position={[ 3.5, 3.5, 0 ]}>
        <boxGeometry args={[ 1, 1, 8 ]} />
      </mesh>
      <mesh material={ anodizedBlackMaterial } position={[ 3.5, 0, 3.5 ]} >
        <boxGeometry args={[ 1, 8, 1 ]} />
      </mesh>
    </group>
  )
}
